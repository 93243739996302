import Vue from "vue";

export default {
    classificar(processoOid, paramsClassificacao) {
        return Vue.axios.post("classificacao", {
            ...paramsClassificacao,
            processoOid
        });
    },

    recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("classificacao/filtro", {
            params: {
                processoOid: paramsFiltro.processoOid,
                opcaoOid: paramsFiltro.opcaoOid,
                nome: paramsFiltro.nome,
                cpf: paramsFiltro.cpf,
                situacoesInscricaoOid: paramsFiltro.situacoesInscricaoOid,
                convocado: paramsFiltro.convocado
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    },
    eliminarCandidatosSemNotaEnem(processoOid) {
        return Vue.axios.post("classificacao/eliminarCandidatosSemNotaEnem/" + processoOid);
}
}
