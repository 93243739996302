<template>
  <div>
    <Consulta
      ref="filtroConsulta"
      titulo="Consulta de Classificação"
      :parametros="paramsFiltro"
      :colunas="headers"
      :ordenacao-colunas="sortBy"
      :consulta-callback="consulta"
      item-key="cpf"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="6">
            <v-autocomplete
              v-model="paramsFiltro.processoOid"
              :rules="[campoObrigatorioRule]"
              :items="processos"
              clearable
              dense
              item-text="nome"
              item-value="oid"
              label="Processo *"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="paramsFiltro.opcaoOid"
              :disabled="!paramsFiltro.processoOid"
              :items="opcoes"
              clearable
              dense
              item-text="nome"
              item-value="oid"
              label="Opção"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="paramsFiltro.nome"
              dense
              label="Nome"
              name="nomeInscricao"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="paramsFiltro.cpf"
              v-mask="'###########'"
              :counter="11"
              dense
              label="CPF"
              maxlength="11"
              name="cpfInscricao"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              multiple
              v-model="paramsFiltro.situacoesInscricaoOid"
              :items="situacoesInscricao"
              clearable
              dense
              item-text="nome"
              item-value="oid"
              label="Situação Inscrição"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="paramsFiltro.convocado"
              :items="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              clearable
              dense
              label="Convocado"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.convocado="{ item }">
        <v-icon v-if="item.convocado" color="primary"> mdi-check </v-icon>
        <v-icon v-else> mdi-minus </v-icon>
      </template>
    </Consulta>
  </div>
</template>

<script>
import classificacaoService from '@/services/classificacao.service';
import situacaoInscricaoService from '@/services/situacaoInscricao.service';
import opcaoService from '@/services/opcao.service';
import processoService from '@/services/processo.service';
import rules from '@/commons/rules';
import Consulta from './Consulta';

export default {
  name: 'ClassificacaoFiltro',
  components: { Consulta },
  data() {
    return {
      loading: false,
      processoSemFila: ['TEE', 'REIN', 'MUD', 'MUF', 'TEF', 'ICA'],
      headers: [
        { text: 'Opção', value: 'nomeOpcao' },
        { text: 'Formação', value: 'formacao' },
        { text: 'Turno', value: 'turno' },
        { text: 'Polo', value: 'polo' },
        { text: 'Posição', value: 'posicao' },
        { text: 'Fila', value: 'fila' },
        { text: 'Total de Pontos', value: 'totalPontosNoCurso' },
        { text: 'Nome', value: 'nome' },
        { text: 'CPF', value: 'cpf' },
        { text: 'Numero ENEM', value: 'numeroEnem' },
        { text: 'Situação', value: 'situacaoInscricao' },
        { text: 'Convocado?', value: 'convocado', align: 'center' },
      ],
      sortBy: ['nomeOpcao', 'formacao', 'turno', 'polo', 'fila', 'posicao'],
      sortDesc: [false],
      situacoesInscricao: [],
      paramsFiltro: {},
      processos: [],
      processo: {},
      opcoes: [],
      consulta: classificacaoService.recuperarFiltro,
    };
  },

  async created() {
    let loader = this.$loading.show();

    await processoService
      .recuperarProcessosPossuiClassificacao()
      .then((response) => {
        this.processos = response.data;
      })
      .catch(() => {});

    await situacaoInscricaoService
      .recuperarSituacoesInscricao()
      .then((response) => {
        this.situacoesInscricao = response.data;
      });
    loader.hide();
  },

  async mounted() {
    if (this.$route.params.processoOid) {
      this.paramsFiltro.processoOid = this.$route.params.processoOid;

      opcaoService
        .recuperarOpcoesConsulta(this.paramsFiltro.processoOid)
        .then((response) => {
          this.opcoes = response.data;
        })
        .catch(() => {});

      processoService
        .recuperarProcesso(this.paramsFiltro.processoOid)
        .then((response) => {
          this.processo = response.data;
          console.log(this.processo.tipoProcesso.codigo);
          console.log(
            this.processoSemFila.includes(this.processo.tipoProcesso.codigo)
          );

          if (
            this.processoSemFila.includes(this.processo.tipoProcesso.codigo)
          ) {
            this.headers.splice(5, 1);
          }
        });

      this.$refs.filtroConsulta.mostrarResultado = true;

      await this.$refs.filtroConsulta.consultar();
    }
  },

  watch: {
    'paramsFiltro.processoOid': {
      handler(oid) {
        if (oid) {
          opcaoService
            .recuperarOpcoesConsulta(oid)
            .then((response) => {
              this.opcoes = response.data;
            })
            .catch(() => {});
        }
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    ...rules,
  },
};
</script>

<style scoped></style>
